// Copyright The OpenTelemetry Authors
// SPDX-License-Identifier: Apache-2.0

import Link from 'next/link';
import * as S from './Banner.styled';
import { useState, useEffect } from 'react';
import { useFlags } from '../../providers/FeatureFlag.provider';
import { getImageWithDelay } from '../../utils/DelayedImage';

const Banner = () => {

  const { loaded, flags } = useFlags();
  const slowLoadEnabled = flags.get('imageSlowLoad')?.enabled;
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    let objectUrl = '';
    if(loaded){
      getImageWithDelay('/images/Banner.png', slowLoadEnabled)
        .then(blob => {
          objectUrl = URL.createObjectURL(blob);
          setImageSrc(objectUrl);
        })
        .catch(error => {
          console.error('Failed to load banner image:', error);
          // Optionally set a fallback image
          // setImageSrc('/images/fallback-banner.png');
        });
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [slowLoadEnabled, loaded]);

  return (
    <S.Banner>
      <S.ImageContainer>
        <S.BannerImg src={imageSrc}/>
      </S.ImageContainer>
      <S.TextContainer>
        <S.Title>The best telescopes to see the world closer (now even more observant!)</S.Title>
        <Link href="#hot-products"><S.GoShoppingButton>Go Shopping</S.GoShoppingButton></Link>
      </S.TextContainer>
    </S.Banner>
  );
};

export default Banner;
